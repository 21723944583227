.btn-icon-1 {
  font-size: 12px;
  padding: 9px 9px !important;
  font-weight: normal;
  border-radius: 6px !important;

  ::ng-deep .btn .svg-icon {
    margin-right: 0 !important;
  }

  div {
    width: 16px !important;
    height: 16px !important;
    display: inline-block !important;

    ::ng-deep span.svg-icon.svg-icon-block svg {
      width: 16px !important;
      height: 16px !important;
    }

    img {
      width: 100% !important;
    }
  }
}

.btn-style {
  font-size: 12px !important;
  padding: 8px 12px !important;
  font-weight: normal;
  border-radius: 6px !important;
  transition: all 0.4s ease-in;
  border: 1px solid transparent !important;

  div {
    width: 16px !important;
    height: 16px !important;
    display: inline-block;
    margin-right: 2px !important;

    span.svg-icon.svg-icon-block svg {
      width: 16px !important;
      height: 16px !important;
      margin-bottom: 1px;
    }

    img {
      width: 100% !important;
    }
  }
}

.btn-chart-style {
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(1.5em + 1.1rem + 2px);
  width: calc(1.5em + 1.1rem + 2px);
  border-radius: 6px;
}

ng-select.dropdown-block.ng-select.ng-select-single {
  font-size: 1.05rem;
  cursor: pointer !important;

  .ng-select-container {
    min-height: 36px !important;
    border-radius: 6px;

    &:active,
    &:focus {
      background-color: #fff;
      border: none;
      border-color: #fff;
      color: #5e6278;
      outline: none;
      box-shadow: 0 0 10px #9ecaed !important;
      transition: color 0.2s ease, background-color 0.2s ease;
    }
  }

  &:active,
  &:focus {
    background-color: #fff;
    border-color: #fff;
    color: #5e6278;
    border: none;
    outline: none;
    box-shadow: 0 0 10px #9ecaed !important;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
}

ng-select.dropdown-block.ng-select.ng-select-multiple {
  font-size: 1.05rem;
  cursor: pointer !important;

  .ng-select-container {
    min-height: 36px !important;
    border-radius: 6px;

    &:active,
    &:focus {
      background-color: #fff;
      border: none;
      border-color: #fff;
      color: #5e6278;
      outline: none;
      box-shadow: 0 0 10px #9ecaed !important;
      transition: color 0.2s ease, background-color 0.2s ease;
    }
  }

  &:active,
  &:focus {
    background-color: #fff;
    border-color: #fff;
    color: #5e6278;
    border: none;
    outline: none;
    box-shadow: 0 0 10px #9ecaed !important;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
}

select.dropdown-block.form-select {
  font-size: 12px;
  cursor: pointer !important;
  min-height: 36px !important;
  border-radius: 6px;

  &:active,
  &:focus {
    background-color: #fff;
    border-color: #fff;
    color: #5e6278;
    border: none;
    outline: none;
    box-shadow: 0 0 10px #9ecaed !important;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
}

.form-group.inputtext-block {
  position: relative;

  .form-control {
    min-height: 36px !important;
    max-height: 36px !important;
    border-radius: 6px;

    &:active,
    &:focus {
      background-color: #fff;
      border-color: #fff;
      color: #5e6278;
      outline: none;
      border-color: #9ecaed;
      box-shadow: 0 0 10px #9ecaed !important;
      transition: color 0.2s ease, background-color 0.2s ease;
    }
  }

  .form-invalid-message {
    position: absolute;
    line-height: 1.0;
    padding-top: 2px;
    padding-left: 2px;
  }
}

.color-picker-block {
  border-radius: 6px;

  .colorpicker-div {
    border-radius: 6px;
    margin-right: 6px;
    width: 40px !important;
  }

  input {
    padding: 6px 10px;
    border-radius: 6px;
    border-color: transparent !important;
    min-height: 15px !important;
    max-height: 27.5px !important;
  }

}

a.mat-menu-trigger.btn.btn-icon.btn-active-color-primary {
  border: 1px solid var(--buttonPrimaryColour) !important;
}

a.mat-menu-trigger.btn.btn-icon.btn-active-color-primary:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  &:hover {
    box-shadow: 0px 0px 4px 0px var(--buttonPrimaryColour) !important;
  }
}

.mat-menu-trigger.btn.btn-icon.btn-active-color-primary {
  .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: var(--buttonPrimaryColour) !important
  }
}

a.mat-menu-trigger.btn.btn-icon.btn-active-color-success {
  border: 1px solid #009b00 !important;
}

a.mat-menu-trigger.btn.btn-icon.btn-active-color-success:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  &:hover {
    box-shadow: 0px 0px 4px 0px #009b00 !important;
  }
}

.mat-menu-trigger.btn.btn-icon.btn-active-color-success {
  .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: #009b00 !important
  }
}

.svg-icon-transparent svg [fill]:not(.permanent):not(g) {
  fill: transparent !important;
}

.mat-menu-panel {
  min-height: 50px !important;
}

.mat-menu-item:hover:not([disabled]) {
  color: var(--bs-primary);
  background-color: #e9f3ff !important;

  .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: #008df5 !important;
  }
}

.mat-focus-indicator.menu-list-block {
  width: 92% !important;
  border: none !important;
  background: none;
  gap: 10px;
  display: flex;
  align-items: center;

  .clone-icon {
    width: 18px !important;

    img {
      width: 18px !important;
      width: 100%;
    }
  }

  &>div {
    width: 20px;

    img {
      width: 100%;
    }
  }
}


.br-6 {
  border-radius: 6px;
}

.border-shadow-grey {
  box-shadow: 2px 2px 6px 1px #ccc;
}

ui-switch {
  width: 50px;
}

.page-item.disabled .page-link {
  color: #b5b5c3 !important;
}

.textarea-field {

  &:active,
  &:focus-visible {
    background-color: #fff;
    color: #5e6278;
    outline: none;
    border-color: #9ecaed !important;
    box-shadow: 0 0 10px #9ecaed !important;
    transition: color 0.2s ease, background-color 0.2s ease;
  }
}

.img-close-icon {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 36px;
  height: 36px;
  padding: 0 !important;
}

.form-control[type="password"] {
  background-position: right calc(2.5em + 0.375rem) center;
  padding-right: 15px !important;
}

// .form-control.is-valid, .was-validated .form-control:valid{background-position: right calc(0.375em + 2.775rem) center;}

.form-control.is-invalid+i,
.was-validated .form-control:invalid+i {
  top: 16px !important;
  bottom: auto;
}

// .password-input .form-control.is-invalid+i, .was-validated .form-control:invalid+i {
//   top: 5px!important;
//   bottom: auto;
// }
.password-input.partner .form-control.is-invalid+i,
.password-input.partner .was-validated .form-control:invalid+i {
  top: 16px !important;
  bottom: auto;
}

.required-asterisk {
  color: red;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.2em;
  line-height: 10px;
}

.card-footer {
  .btn {
    float: left;
    margin-right: 10px;
  }
}

.password-input-block {
  position: relative;
  // margin-bottom: 15px;

  /*max-height: 43px;*/
  input {
    margin-bottom: 0 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form-control.is-invalid+i,
  .was-validated .form-control:invalid+i {
    top: auto !important;
  }

  .form-control.is-invalid {
    background-position: right calc(0.375em + 2.375rem) center !important;
  }

  i {
    position: absolute;
    right: 0;
    transform: translate(-100%, -190%);
    cursor: pointer;
  }
}

.user-email {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

select,
.form-control {
  min-height: 40px;
  max-height: 40px;
}

.ng-select .ng-select-container {
  cursor: default;
  display: flex;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-color: #dcdcdc;
  background-color: #f7f7f7;
  min-height: 40px;
  padding: 0.825rem 0.5rem;
  font-size: 1.15rem;
  border-radius: 0;
}

.partner_multiselect.ng-select {

  .ng-select-container {
    padding: 0;

    .ng-value-container {
      padding: 0 15px;

      .ng-input {
        padding: 0;
      }
    }

  }

}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #e9e9e9;
  font-size: 12px;
  margin-bottom: 2px;
}

.switch.checked {
  background-color: #64bd63 !important;
}

.switch.checked small,
.switch.checked .small {
  right: 0 !important;
}

.switch.switch-medium>.switch-pane>span {
  font-weight: 600 !important;
}

.align-items-side {
  display: flex;
  justify-content: space-between;
}

.space-between {
  margin-right: 5px;
}

.span-opacity {
  opacity: 0.5;
}

.span-input {
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
}

.div-position {
  position: relative;
}

.disabled {
  pointer-events: none
}


:host {
  width: 100%;

  @media (min-width: 992px) {
    .login-form {
      width: 100%;
      max-width: 450px;

      .mat-form-field {
        width: 100%;
      }
    }
  }

  .password-input {
    position: relative;
    margin-bottom: 15px;

    input {
      margin-bottom: 0 !important;
    }

    i {
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 15px;
    }
  }

}

.pencil-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}

.container-div {
  position: relative;
}

.mat-paginator-outer-container .mat-paginator-container {
  justify-content: space-between;
}

.mat-focus-indicator svg {
  color: #a1a5b7
}

.mat-focus-indicator {
  border: 1px solid #a1a5b7 !important;
  margin-left: 5px !important;
  border-radius: 10px !important;
  background: #f5f8fa;
  height: 34px !important;
  width: 34px !important;
  line-height: 34px !important;
}

.mat-paginator-icon {
  width: 20px !important;
}

.mat-paginator-range-label {
  margin: 0 10px;
  font-size: 16px;
}

// .ng-select .ng-select-container .ng-value-container .ng-input>input{
//   height: 40px;
// }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 12px;
  padding-top: 5px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0px !important;
}

textarea {
  -webkit-appearance: textfield;
}

body .popover-body {
  padding: 0;
}

.btn-default {
  background: #f5f8fa;
}

.login_signin_form {

  .password-input {
    i {
      top: 17px;
      margin: 0;
    }

  }

}

.display-number {

  .ng-select {
    .ng-select-container {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 45px;
      padding: 0;
    }

  }

}


@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 1200px;
  }

  .aside .aside-logo {
    padding: 0 15px;
  }

}

body#kt_body.swal2-height-auto {
  height: 100% !important;
}

.splash-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
  font-family: Poppins, Helvetica, 'sans-serif';

  img {
    margin-left: calc(100vw - 100%);
    margin-bottom: 30px;

  }
}

.group-select-box {
  min-height: 200px;
  background: #f5f8fa;
  text-align: left;
}

input::-webkit-credentials-auto-fill-button {
  display: none;
  background: none;
}

.ng-select .ng-clear-wrapper .ng-clear {
  line-height: normal !important;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease;
}

.hyper-link {
  color: blue !important;
  cursor: pointer;
}

.card-body .popover {
  padding: 0;
  text-align: left;
  border-radius: 6px;
}

.card>.card-header {
  min-height: 60px;
}

.example-preview {
  border: 2px solid #F3F6F9;
  padding: 0.75rem 1.5rem;
  border-radius: 0;
  position: sticky;
  position: -webkit-sticky;
  top: 65px;
  z-index: 99;
  background: #fff;
}

.disable-option {
  max-width: 130px;
}

.treeview-container {
  padding: 15px;
  background: #fff;
  // min-height: 600px;

  .treeview-item {
    background: #f5f8fa;
    padding: 5px 10px;
    margin-bottom: 10px;

    .treeview-item {
      background: #fff;
      margin-top: 5px;
      border: 1px solid #f3f3f3;
    }

    .form-inline {
      display: flex;
      align-items: center;
      margin-bottom: 0px !important;

      i {
        color: #009ef7;

        svg {
          width: 15px;
          height: 13px;
          line-height: normal;
        }


      }

      .form-check {
        padding-left: 0px;
      }

      .form-check-label {
        text-transform: capitalize;
        font-size: 12.35px;
        color: #181c32;
        font-weight: 600;
      }
    }

  }

}

.quick-view {

  .col-lg-6 {
    width: 100%;
  }

}


.separator-vertical {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background: #009ef7;
    width: 5px;
    height: 1px;
    right: -3px;
    top: 9px;
  }

}

button[aria-expanded=false] {
  transition: 0.2s;
}

tr td button[aria-expanded=true] {
  transform: rotate(90deg);
  transition: 0.2s;
}


.table-fixed {
  table-layout: fixed;
}


.interface-table,
.interface-table-inner {
  tr {
    td {
      &:first-child {
        width: 50px;
      }

      &:nth-child(2) {
        width: 70px;
      }

      &:nth-child(3) {
        width: 100px;
      }

      &:nth-child(4) {
        width: 100px;
      }

      &:nth-child(5) {
        width: 100px;
      }

      &:nth-child(6) {
        width: 160px;
      }

      &:nth-child(7) {
        width: 110px;
      }

      &:nth-child(8) {
        width: 100px;
      }

      &:nth-child(9) {
        width: 50px;
      }
    }

    th {
      &:first-child {
        width: 50px;
      }

      &:nth-child(2) {
        width: 70px;
      }

      &:nth-child(3) {
        width: 100px;
      }

      &:nth-child(4) {
        width: 100px;
      }

      &:nth-child(5) {
        width: 100px;
      }

      &:nth-child(6) {
        width: 160px;
      }

      &:nth-child(7) {
        width: 110px;
      }

      &:nth-child(8) {
        width: 100px;
      }

      &:nth-child(9) {
        width: 36px;
      }
    }
  }
}

.interface-table {
  min-width: 900px;
}

.min-w-900 {
  min-width: 900px;
}

.min-w-1200 {
  min-width: 1200px;
}

.min-w-1900 {
  min-width: 1900px;
}

.legs-table {
  min-width: 1200px;
}

.legs-table,
.legs-table-inner {
  tr {

    td,
    th {
      &:first-child {
        width: 20px;
      }

      &:nth-child(2) {
        width: 40px;
      }

      &:nth-child(3) {
        width: 45px;
      }

      &:nth-child(4) {
        width: 120px;
      }

      &:nth-child(5) {
        width: 110px;
      }

      &:nth-child(6) {
        width: 80px;
      }

      &:nth-child(7) {
        width: 80px;
      }

      &:nth-child(8) {
        width: 90px;
      }

      &:nth-child(9) {
        width: 80px;
      }

      &:nth-child(10) {
        width: 80px;
      }

      &:nth-child(11) {
        width: 100px;
      }
    }
  }
}

.legs-table-inner-status {
  tr {

    td,
    th {
      &:first-child {
        width: 15px;
      }

      &:nth-child(2) {
        width: 10px;
      }

      &:nth-child(3) {
        width: 80px;
      }

      &:nth-child(4) {
        width: 40px;
      }

      &:nth-child(5) {
        width: 60px;
      }

      &:nth-child(6) {
        width: 80px;
      }

      &:nth-child(7) {
        width: 80px;
      }

      &:nth-child(8) {
        width: 80px;
      }

      &:nth-child(9) {
        width: 80px;
      }

      &:nth-child(10) {
        width: 80px;
      }

      &:nth-child(11) {
        width: 100px;
      }
    }
  }
}

.speed-test-view td:first-child {
  text-align: right;
  padding-right: 10px;
}


// .overflow-x{
//   overflow-x: auto;
//   // min-height: 150px;
// }

.top-branding-header {
  .menu-item .menu-link .menu-title {
    white-space: nowrap;
  }
}

.top-menu-logo {
  white-space: nowrap;
}

.organizations-tree {
  .treeview-container {
    padding: 0;
  }

  .dropdown-divider {
    margin: 1.2rem 0;
    border-top: 1px solid rgb(203 203 203 / 15%);
  }
}

a.btn.btn-sm.btn-primary.text-black {
  color: #000 !important;
}

.charlimit {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.xng-breadcrumb-root {
  font-weight: normal;
}

.gateways,
.gatewaysNAT,
.nat-rules,
.port-forwards {
  table {
    tr {

      td,
      th {
        vertical-align: top;

        &:first-child {
          width: 25px;
          padding: 0;
          vertical-align: top;
          padding-top: 16px;
        }

        &:nth-child(2) {
          width: 35px;
          max-width: 80px;
          vertical-align: top;
          padding-top: 14px;

          .form-check {
            justify-content: center;
          }
        }
      }
    }
  }
}

.gatewaysNAT {
  table {
    tr {

      td,
      th {
        &:nth-child(4) {
          min-width: 324px;
        }
      }
    }
  }
}

.gateways {
  table {
    tr {

      td,
      th {
        &:nth-child(8) {
          min-width: 324px;
        }
      }
    }
  }
}

.nat-rules {
  table {
    tr {

      td,
      th {
        &:nth-child(5) {
          min-width: 324px;
        }
      }
    }
  }
}

.port-forwards {
  table {
    tr {

      td,
      th {
        &:nth-child(3) {
          min-width: 80px;
        }

        &:nth-child(9) {
          min-width: 80px;
        }

        &:nth-child(8) {
          min-width: 324px;
        }
      }
    }
  }
}

.cdk-drag-preview {
  display: flex;
  width: 100% !important;

  td {
    margin: 0px 5px;
    width: 100%;

    &:first-child {
      width: 70px;
      max-width: 25px;
    }

    &:nth-child(2) {
      width: 90px;
      max-width: 70px;

    }

  }

}

.active-bonders .bg-success {
  background-color: #009b00 !important;
}



.white-space-normal {
  white-space: normal;
}

/*table td .text-danger {
  position: absolute;
}*/

.alert-warning {
  color: #3f4254;
  background-color: #fff8dd;
  border-color: #ffeeb3;
}

ngb-popover-window.bs-popover-bottom-end>.popover-arrow,
ngb-popover-window.bs-popover-top-end>.popover-arrow {
  right: 0.8em !important;
}

.menu-lg-row>.menu-item {
  white-space: nowrap;
}

// #kt_aside_toggle{
//   position: fixed;
//     left: 245px;
//     background: #fff;
//     width: 30px !important;
//     box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 20%) !important;
//     height: 30px;
//     transition: width 0.3s ease;
// }

// [data-kt-aside-minimize=on] #kt_aside_toggle{
//   left: 55px;
// }

// [data-kt-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating){
//   left: 245px;
// }

// .btn.btn-primary {
//   font-size: 14px;
//   padding: 9px 12px !important;
//   font-weight: normal;
// }
.border-start {
  border-left: 1px solid #dcdcdc !important;
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  background-color: #fff;
  border-bottom: 1px solid transparent;
  color: #009ef7;
}

.login_signin_form small {
  font-style: italic;
  color: #000;
}

.login_signin_form input.form-control {
  width: 100%;
  max-width: 416px;
  height: 48px !important;
  background: #F5F6F6 !important;
  border: 1px solid #DBDBDB !important;
  max-height: 48px;
}

.login_signin_form button#kt_sign_in_submit {
  // min-height: 48px;
  font-weight: bold;
  // background: #0D91DB !important;
  // opacity: 1;
}

.login_signin_form {
  position: relative;
}

// .login_signin_form .super-admin-text {
//   position: absolute;
//   right: 20px;
//   top: -40px;
// }

.login_signin_form .super-admin-text {
  text-align: left;
  margin-bottom: 5px;
}

@media (max-width: 1599px) {

  .aside-menu .menu-item {
    margin-bottom: 0rem;
  }

}

tr:nth-child(even) td {
  background: #f7f7f7;
}

td tr:nth-child(even) td {
  background: transparent;
}

td .btn-icon {
  border: 1px solid #e9e9e9 !important;
}

tr:hover td {
  background: #f1f1f1;
}

.top-menu-bar,
.header-fixed .top-branding-header {
  background: #1e1e2d;
}

.top-menu-bar .menu-sub-lg-dropdown {
  background: #1e1e2d;
}

// .aside-logo {
//     border-bottom: 1px solid #f5f6f7;
// }
.sub-tab .nav-line-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #009ef7;
}

.text-light-dark-custom {
  color: $tab-color;
}

li.xng-breadcrumb-separator {
  margin: 0 05px;
}

.xng-breadcrumb-trail {
  color: rgba(0, 0, 0, 0.7) !important;
}

.xng-breadcrumb-link:hover {
  color: rgba(0, 0, 0, 0.7) !important;
}

.form-control.form-control-solid {
  border-color: #dcdcdc;
  background-color: #f7f7f7;
}

.ng-select.ng-select-single .ng-select-container {
  border: 1px solid #dcdcdc;
  background: #f7f7f7;
}

.display-number .ng-select.ng-select-single .ng-select-container {
  border: none;
  background: none;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  background: #f7f7f7;
}

.latestNotification .ng-select span.ng-value-label {
  font-size: 13px;
  padding: 0px 2px;
  color: #333;
}

.sd-wan-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.sd-wan-list {
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sd-wan-list>div {
  width: 100%;
}

table {
  width: 100%;

}

.card-body p.badge,
.card-body small.badge {
  line-height: normal;
}


.upState .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #87f97b;
}

.downState .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #f8255a;
}

.downState .svg-icon svg [fill] {
  fill: #f8255a !important;
}

.upState .svg-icon svg [fill] {
  fill: #87f97b !important;
}

.upState .symbol {
  border: 1px solid #a3e59b;
}

.downState .symbol {
  border: 1px solid #ffb2c5;
}

.progresss-status {
  width: 150px;
  height: 150px !important;
  float: left;
  line-height: 150px;
  background: none;
  margin: 20px;
  box-shadow: none;
  position: relative;
}

.progresss-status:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
}

.progresss-status>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progresss-status .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progresss-status .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progresss-status .progress-right {
  right: 0;
}

.progresss-status .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

// .progresss-status .progress-value {
//   width: 90%;
//   height: 90%;
//   border-radius: 50%;
//   background: #fff;
//   font-size: 24px;
//   color: #333;
//   line-height: normal;
//   text-align: center;
//   position: absolute;
//   top: 5%;
//   left: 5%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-flow: column;
// }

.progresss-status .progress-value {
  width: 82%;
  height: 82%;
  border-radius: 50%;
  background: #fff;
  font-size: 24px;
  color: #333;
  line-height: normal;
  text-align: center;
  position: absolute;
  top: 9%;
  left: 9%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  z-index: 1;
}


.progresss-status.blue .progress-bar {
  border-color: #50cd89;
}

.progresss-status.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progresss-status.yellow .progress-bar {
  border-color: #50cd89;
}

.progresss-status.yellow .progress-right .progress-bar {
  animation: loading-3 1.8s linear forwards;
}

.progresss-status.yellow .progress-left .progress-bar {
  animation: none;
}

.progress-value span {
  line-height: 16px;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.top-branding-header .breadCrumbs-text {
  color: #fff;
}

.speed-test-view tr:nth-child(even) td {
  background: #fff;
}

.speed-test-view tr:hover td {
  background: #fff;
}

.active-bonders-name {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.border-bottom-light {
  border-bottom: 1px solid #f5f5f5 !important;
}

.max-w-80px {
  max-width: 80px;
}

.recent-activity .timeline-label .timeline-label {
  width: 130px;
}

.recent-activity .timeline-label:before {
  content: "";
  left: 141px;
}

.event-logs .timeline-label .timeline-label {
  width: 110px;
}

.event-logs .timeline-label:before {
  content: "";
  left: 120px;
}

.statusArrow span {
  position: absolute;
  right: 43%;
  top: 26%;
}

.statusArrow {
  position: relative;
}

.empty-cell:empty {
  display: none !important;
}

.card>.card-header+.card-body .timeline-label {
  padding-top: 0 !important;
}

.card:last-child .recent-activity .timeline-label {
  padding-bottom: 0 !important;
}

.progress {
  background-color: #a1a5b7;
}

.bg-danger {
  background-color: #c00000 !important;
}

.bg-success {
  background-color: #009b00 !important;
}

.badge-light-danger {
  background: #f5dbd9;
  border: 1px solid #ebafa6;
  color: #a32100;
  font-weight: 500;
  min-width: 100px;
}

.svg-icon.svg-icon-success svg {
  color: #009b00;
}

.svg-icon.svg-icon-danger svg {
  color: #c00000;
}

.svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #009b00;
}

.statusArrow {

  .svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
    fill: #6fbadd;
  }

  .svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
    fill: #769edf;
  }
}


.svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6fbadd;
}


.status-down .svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
  fill: #555;
}

.svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #c00000;
}

.login-page-logo img {
  max-height: 120px;
  max-width: 385px;
}

#kt_aside_toggle {
  background: #fff;
  height: 30px !important;
  width: 30px !important;
  transform: translate(-50%, -50%) !important;
  left: 100% !important;
  top: 50% !important;
  position: absolute !important;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.3) !important;
  border-radius: 5px;
}

.aside .aside-logo {
  position: relative;
}

@media (min-width: 992px) {
  .aside-fixed .aside {
    overflow: visible;
    white-space: normal;
    justify-content: start;
  }

  .aside .aside-logo {
    justify-content: start;
    /* white-space: nowrap; */
  }

  //   [data-kt-aside-minimize=on] .aside .aside-menu {
  //     width: 75px;
  // }

  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu,
  [data-kt-aside-minimize=on] .aside:not(:hover) .aside-menu {
    width: 75px;
    transition: width 0.3s ease;
  }

  body .aside .menu .menu-item .menu-link .menu-title,
  body .menu .menu-item .menu-link .menu-title {
    white-space: nowrap;
  }

  [data-kt-aside-minimize=on] .aside:not(.aside-hoverable) #kt_aside_menu_wrapper,
  [data-kt-aside-minimize=on] .aside:not(:hover) #kt_aside_menu_wrapper {
    overflow: hidden;
    transition: width 0.3s ease;
  }

}

.leg-box-mbb {
  background: #c7c7c7;
  border: 1px solid #c7c7c7;
  height: 13px;
  width: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1px;
}

.active-card .svg-icon.svg-icon-8 svg,
.active-card .svg-icon.svg-icon-7 svg {
  width: 12px !important;
  height: 12px !important;
}

.active-card .svg-icon.svg-icon-8.leg-box-mbb svg {
  min-width: 11px !important;
  height: 11px !important;
}

.active-card .svg-icon.svg-icon-7.eth2-icon svg {
  height: 13px !important;
  margin-bottom: 2px;
}

.active-card {
  min-width: 230px;
  margin: 5px;
}

@media only screen and (max-width: 1599px) and (min-width: 1300px) {

  // .active-bonders .active-bonders-inner{ min-height: 170px; }

  .active-card {
    margin: 5px;
    min-width: 210px;
  }

  .active-card-main {
    max-width: 95%;
    margin: 0 auto;
  }

}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  box-shadow: none;
}

.empty>div:empty {
  display: none;
}

@media (max-width: 1024px) {

  .active-card {
    min-width: 210px;
  }

}

.status_note {
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

span.alert-text {
  background: #feecb5;
  color: #565656;
  border: 1px solid #ffdc0b;
  display: flex;
  padding: 6px;
  line-height: normal;
  font-size: 12px;
  border-radius: 5px;
}

// .bonds-list.table thead tr th span {
//   position: absolute;
//   top: 15px;
// }
// table thead tr th {
//   position: relative;
// }

// .bonds-list.table thead tr th span {
//   position: absolute;
//   top: 15px;
// }

.bonds-list {
  &.table {
    thead {
      tr {
        th {
          position: relative;

          span {
            position: absolute;
            top: 15px;
          }
        }
      }
    }
  }
}

.sd-wan-bonds-list {
  position: absolute;
  z-index: 9;
  top: -45px;
  right: 6px;
}


// th.bond-status {
//   max-width: 50px;
//   /* min-width: 70px; */
//   padding: 0;
//   text-align: center;
// }

.documentaion-menu {
  position: fixed;
  bottom: 10px;
  width: 100%;
  max-width: 265px;
  left: 0;
}

body {
  .app-engage {
    .svg-icon {
      svg {
        color: #555555;

        [fill]:not(.permanent):not(g) {
          fill: #555555;
        }

      }
    }

    .popover-body {
      padding: 10px 15px;
      display: flex;
      flex-flow: column;

      a {
        color: #555 !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.recent-activity {
  .timeline-content {
    word-break: break-word;
  }

  .text-muted {
    color: #555 !important;
  }
}

.shared-bond-list {

  thead {
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative;

    th:nth-child(1) {
      width: 10%;
    }

    th:nth-child(2) {
      width: 20%;
    }

    th:nth-child(3) {
      width: 40%;
    }
  }

  tbody {

    display: block;
    max-height: 230px;
    overflow-y: scroll;

    tr {
      display: table;
      table-layout: fixed;
      width: 100%;

      td:nth-child(1) {
        width: 10%
      }

      td:nth-child(2) {
        width: 20%;
      }

      td:nth-child(3) {
        width: 40%;
      }

      // td:nth-child(3){
      //   width: 200px;
      // }
    }
  }

}

.palette {
  display: flex;
  align-items: center;
  width: 100%;
}


.place {
  width: 40px;
  height: 40px;

  span {
    position: absolute;
    text-align: center;
    transition: all .2s;
    bottom: 0;
    color: #fff;
    opacity: 0;
    background-color: rgba(0, 0, 0, .1);
    padding: 3px 6px;
    border-radius: 0 6px 0 0;
    cursor: pointer;
    overflow: hidden;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.place:hover span {
  opacity: 1;
}

.form-check.form-check-custom.form-check-sm .form-check-input {
  height: 16px;
  width: 16px;
}

/* -=-=-==-=-=--=-=-=-= shimmer effect start -=-=-==-=-=--=-=-=-=  */
.shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.box {
  height: 40px;
  width: 230px;
}

.lines {
  height: 10px;
  margin-top: 10px;
  width: 85px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.lines.round {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.loadingActivityData .lines:first-child {
  width: 11%;
}

.loadingActivityData .lines:last-child {
  width: 84%;
}

.loadingActivityData .lines.round {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.lines.box.shine {
  width: 60px;
  height: 60px;
}

/* -=-=-==-=-=--=-=-=-= shimmer effect end -=-=-==-=-=--=-=-=-=  */

[data-kt-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) .menu-item,
[data-kt-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) .menu {
  transition: width 0.3s ease;
  width: 265px;
}

[data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu-item,
[data-kt-aside-minimize=on] .aside:not(:hover) .aside-menu .menu-item,
[data-kt-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu,
[data-kt-aside-minimize=on] .aside:not(:hover) .aside-menu .menu {
  width: 75px;
  transition: width 0.3s ease;
}

/*progress bar custom start*/
.timeBar {
  content: "";
  height: 2px;
  position: absolute;
  width: 100%;
  animation: progressTimeBar 11s ease-in-out;
  animation-fill-mode: both;
  top: 55px;
  right: 0;
  left: 0;
  box-shadow: 5px 0px 15px 0px var(--buttonPrimaryColour);
  left: auto;
  background-color: var(--buttonPrimaryColour);
}

@keyframes progressTimeBar {
  100% {
    width: 0%;
  }

  0% {
    width: 100%;
  }

}

/*progress bar custom end*/
.organization .tooltip .tooltip-inner {
  max-width: 600px;
}

.bond-id {
  min-width: 80px;
}

th.bond-name {
  min-width: 201px;
}

.bond-space {
  min-width: 150px;
}

.bond-legs {
  min-width: 150px;
}

.bond-current-aggregator {
  min-width: 180px;
}

.bond-version {
  min-width: 110px;
}

.bond-options {
  min-width: 90px;
}

.bond-classification-profile {
  min-width: 190px;
}

.bond-qoS-profile {
  min-width: 110px;
}

.bond-packet-distribution {
  min-width: 170px;
}

.bond-tcp-proxy {
  min-width: 100px;
}

.bond-bonder-id {
  min-width: 100px;
}

.bond-distro {
  min-width: 150px;
}

.bond-Kernel {
  min-width: 190px;
}

.bond-cpu {
  min-width: 210px;
}

.bond-memory {
  min-width: 90px;
}

.bond-system {
  min-width: 90px;
}

.bond-serial-number {
  min-width: 140px;
}

.bond-asset-tag {
  min-width: 100px;
}

.bond-flow-collectors {
  min-width: 140px;
}

.bond-connected-ip {
  min-width: 190px;
}

.bond-upload,
.bond-download {
  min-width: 110px;
}

.bond-status {
  min-width: 70px;
}

.bond-primary-aggregator,
.bond-aggregator-failback {
  min-width: 170px;
}

.bond-secondary-aggregator {
  min-width: 190px;
}

.bond-tunnel-security {
  min-width: 140px;
}

.bond-encryption-cipher {
  min-width: 150px;
}

.bond-circuit-id {
  min-width: 100px;
}

.bond-qoS-profile {
  min-width: 130px;
}

.form-check-input:disabled {
  opacity: 0.4;
}

.group-permission table:empty {
  display: none;
}

.preloader .lines:first-child {
  width: 100%;
  height: 28px;
}

.switch-width {
  width: 54px !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #7e8299a1 transparent;
}

.icon-badge {
  position: absolute;
  z-index: 2;
  margin-top: -16px;
  margin-left: 10px;
}

.badge-circle-icon {
  display: inline-block;
  background-color: #00db61;
  border-radius: 50%;
  color: #fff;
  padding: 4px 4px;
  position: relative;
}

.pulsate::before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: -2px;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  border-radius: 50%;
  border: 6px double #16dd6f;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }

  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.moving-border-success {
  position: relative;
  padding: 3px;
  z-index: 1;
  background: #c9ebab;
  border-radius: 6px;
  font-size: 0.85rem;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
}

.moving-border-success::before,
.moving-border-success::after {
  content: "";
  position: absolute;
  inset: -1px;
  z-index: -1;
  background: linear-gradient(var(--angle), #009b00, #C3F2FF, #009b00);
  animation: border-rotate 5s linear infinite;
  border-radius: 6px;
}

.moving-border-success::after {
  filter: blur(4px);
}

.moving-border-primary {
  position: relative;
  padding: 3px;
  z-index: 1;
  background: #ccecfd;
  border-radius: 6px;
  font-size: 0.85rem;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
}

.moving-border-primary::before,
.moving-border-primary::after {
  content: "";
  position: absolute;
  inset: -1px;
  z-index: -1;
  background: linear-gradient(var(--angle), #2a4bdf, #C3F2FF, #2a4bdf);
  animation: border-rotate 4s linear infinite;
  border-radius: 6px;
}

.moving-border-primary::after {
  filter: blur(4px);
}

@keyframes border-rotate {
  0% {
      --angle: 0deg;
  }

  100% {
      --angle: 360deg;
  }
}