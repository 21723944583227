.xng-breadcrumb-list
{
    position: relative!important;
    /* left: calc(22vw)!important; */
    left: calc(0vw)!important;
}
.delegateList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  max-width: 550px;
}
.delegateList > * {
  white-space: nowrap;
}
.text-underline {
    text-decoration: underline!important;
}
.text-hover {
    cursor: pointer!important;
}
.hoverGrab {
    cursor: all-scroll!important;
}
.hoverIcon {
    cursor: pointer!important;
}
.notallowIcon {
    cursor: not-allowed!important;
}
.availability_icons {
    margin-bottom: 10px;
}
.availability_icons span {
    margin-right: 5px;
}
.availability_icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 2px;
    border-radius: 4px;
}
.availability_icon.available {
    background: #2A78E4;
}
.availability_icon.used {
    background: #CF2A28;
}
.availability_icon.delegated {
    background: #999999;
}
.availability_list {
    display: flex;
}
.availability_list div {
    display: inline-flex;
    width: 60%;
    justify-content: center;
}
.availability_list div:first-child {
    width: 20%;
    justify-content: flex-end;
}
.availability_list div:last-child {
    width: 20%;
    justify-content: flex-start;
}
.availability_list div .popover-body {
    width: 100%;
}
.availability_list div .popover-body .nblAvl {
    font-size: 11px;
}
.availability_list .availability_icon {
    border-radius: 0;
}
.availability_list .availability_icon.tl-corner {
    border-top-left-radius: 4px;
}
.availability_list .availability_icon.tr-corner {
    border-top-right-radius: 4px;
}
.availability_list .availability_icon.bl-corner {
    border-bottom-left-radius: 4px;
}
.availability_list .availability_icon.br-corner {
    border-bottom-right-radius: 4px;
}
.ng-select .ng-select-container {
    padding: 0!important;
    min-height: 40px!important;
    /* height: 40px!important; */
}
.ps-30 {
    padding-left: 7.5rem !important;
}
.ps-40 {
    padding-left: 10rem !important;
}
.ps-50 {
    padding-left: 12.5rem !important;
}
.label.label-badge {
    font-size: 10.999992px;
    font-weight: 400;
    letter-spacing: .03em; 
    line-height: 10.999992px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 12px 1px;
    border-radius: 12px;
    border: 1px solid #737373;
    height: 21px;
    margin: 0 6px 0 0;
    white-space: nowrap;
    color: #565656;
}
.label.label-warning {
    background: #f5e7b9;
    color: #a37800;
    border: 1px solid #b59b69;
}
.label.label-success {
    background: #dff0d0;
    color: #266900;
    border: 1px solid #60b515;
}
.label.label-danger {
    background: #f5dbd9;
    color: #a32100;
    border: 1px solid #ebafa6;
}
