.page-link {
    color: var(--buttonPrimaryColour) !important;
    border: 1px solid transparent !important;

    &:hover {
        border-radius: 6px !important;
        border: 1px solid var(--buttonPrimaryColour) !important;
        color: var(--buttonPrimaryColour) !important;
        background-color: transparent !important;
    }
}

body .page-item.active .page-link {
    background-color: var(--buttonPrimaryColour) !important;
    color: #fff !important;
    border-radius: 6px !important;

    &:hover {
        border: 1px solid var(--buttonPrimaryColour) !important;
        color: var(--buttonPrimaryColour) !important;
        background-color: transparent !important;
    }
}

.swal2-actions {
    button.swal2-styled {
        border-radius: 6px !important;
        border: 1px solid transparent !important;
        transition: all 0.4s ease-in-out;
    }

    button.swal2-confirm {
        background-color: var(--buttonPrimaryColour) !important;
        color: #fff !important;

        &:hover {
            background-image: none !important;
            background-color: transparent !important;
            color: var(--buttonPrimaryColour) !important;
            border: 1px solid var(--buttonPrimaryColour) !important;
            transition: all 0.4s ease-in-out;
        }

        &:focus {
            box-shadow: none !important;
        }
    }

    button.swal2-cancel,
    button.swal2-deny {
        background-color: var(--buttonSecondaryColour) !important;
        color: #fff !important;

        &:hover {
            background-image: none !important;
            background-color: transparent !important;
            color: var(--buttonSecondaryColour) !important;
            border: 1px solid var(--buttonSecondaryColour) !important;
            transition: all 0.4s ease-in-out;
        }

        &:focus {
            box-shadow: none !important;
        }
    }
}

.logo-text {
    color: var(--brandingTextColour) !important;
}

.header-fixed .top-branding-header {
    background: var(--navbgColour) !important;
}

/* top header background css*/

.top-menu-bar,
.top-menu-bar .menu-sub-lg-dropdown {
    background: var(--navbgColour) !important;
}

.aside,
.aside-logo {
    background: var(--navbgColour) !important;
}

body .aside.aside-dark {
    background: var(--navbgColour) !important;
}

body .aside.aside-dark .aside-logo {
    background: var(--navbgColour) !important;
}

body .aside .menu .menu-item .menu-link .menu-title,
body .menu .menu-item .menu-link .menu-title {
    color: var(--navLinkColour) !important;
}

.top-branding-header .breadCrumbs-text {
    color: var(--navLinkColour) !important;
}

.aside .menu .menu-item .menu-link.active .menu-title {
    color: var(--navLinkActiveColour) !important;
}

.aside .menu .menu-item.show>.menu-link .menu-title {
    color: var(--navLinkActiveColour) !important;
}

body .menu-state-title-primary .menu-item .menu-link.active .menu-title {
    color: var(--navLinkActiveColour) !important;
}

.aside .menu .menu-item .menu-link.active,
body .menu .menu-item .menu-link.active {
    background: var(--navLinkbackgroundColour) !important;
}

.aside .menu .menu-item .menu-link .menu-title:hover,
body .menu .menu-item .menu-link .menu-title:hover {
    color: var(--navLinkHoverColour) !important;
}

.aside-dark .menu .menu-item.here>.menu-link,
.aside-dark .menu .menu-item.show>.menu-link {
    background: var(--navLinkbackgroundColour) !important;
}

.aside .menu .menu-item.hover>.menu-link:not(.disabled):not(.active),
.aside .menu .menu-item .menu-link:hover:not(.disabled):not(.active),
body .menu .menu-item.hover>.menu-link:not(.disabled):not(.active),
body .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
    background-color: var(--navLinkbackgroundColour) !important;
    color: var(--navLinkHoverColour) !important;
}

body a {
    color: var(--linkColour) !important;
}

.menu-sub-lg-dropdown {
    background: var(--navbgColour) !important;
}

/* body p{ color: var(--textColour) !important;  } */

/* change body link hover color*/

body a:hover {
    color: var(--linkHoverColour) !important;
}

button.btn.btn-danger.btn-icon-1 {
    border: 1px solid var(--buttonDangerColour) !important;
    background-color: transparent !important;
    color: var(--buttonDangerColour) !important;

    span.svg-icon.svg-icon-block {

        path,
        rect {
            fill: var(--buttonDangerColour) !important;
        }
    }

    div {
        i {
            color: var(--buttonPrimaryColour) !important;
        }
    }

    &:hover {
        background-color: var(--buttonDangerColour) !important;
        color: var(--buttonTextColour) !important;
        border: 1px solid var(--buttonDangerColour) !important;

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: var(--buttonTextColour) !important;
            }
        }

        div {
            i {
                color: var(--buttonTextColour) !important;
            }
        }
    }
}

button.btn.btn-primary.btn-icon-1 {
    border: 1px solid var(--buttonPrimaryColour) !important;
    background-color: transparent !important;
    color: var(--buttonPrimaryColour) !important;

    span.svg-icon.svg-icon-block {

        path,
        rect {
            fill: var(--buttonPrimaryColour) !important;
        }
    }

    div {
        i {
            color: var(--buttonPrimaryColour) !important;
        }
    }

    &:hover {
        background-color: var(--buttonPrimaryColour) !important;
        color: var(--buttonTextColour) !important;
        border: 1px solid var(--buttonPrimaryColour) !important;

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: var(--buttonTextColour) !important;
            }
        }

        div {
            i {
                color: var(--buttonTextColour) !important;
            }
        }
    }
}

a.btn.btn-primary.btn-chart-style.active,
button.btn.btn-primary.btn-chart-style.active {
    background-color: var(--buttonPrimaryColour) !important;
    color: var(--buttonTextColour) !important;
    border: 1px solid var(--buttonPrimaryColour) !important;

    span.svg-icon.svg-icon-block {

        path,
        rect {
            fill: var(--buttonTextColour) !important;
        }
    }

    &:hover {
        background-color: transparent !important;
        color: var(--buttonPrimaryColour) !important;
        border: 1px solid var(--buttonPrimaryColour) !important;
        transition: all 0.4s ease-in-out;

        i {
            color: var(--buttonPrimaryColour) !important;
        }

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: var(--buttonPrimaryColour) !important;
            }
        }
    }
}

a.btn.btn-chart-style {
    background-color: transparent !important;
    color: var(--buttonPrimaryColour) !important;
    border: 1px solid #ccc !important;

    span.svg-icon.svg-icon-block {

        path,
        rect {
            fill: var(--buttonPrimaryColour) !important;
        }
    }

    &:hover {
        background-color: transparent !important;
        color: var(--buttonPrimaryColour) !important;
        border: 1px solid var(--buttonPrimaryColour) !important;
        transition: all 0.4s ease-in-out;

        i {
            color: var(--buttonPrimaryColour) !important;
        }

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: var(--buttonPrimaryColour) !important;
            }
        }
    }
}


button.btn.btn-primary.btn-style {
    border-color: var(--buttonPrimaryColour) !important;
    background-color: var(--buttonPrimaryColour) !important;
    color: var(--buttonTextColour) !important;
    border: 1px solid var(--buttonPrimaryColour) !important;

    span.svg-icon.svg-icon-block {

        path,
        rect {
            fill: var(--buttonTextColour) !important;
        }
    }

    &:hover {
        background-color: transparent !important;
        color: var(--buttonPrimaryColour) !important;
        border: 1px solid var(--buttonPrimaryColour) !important;
        transition: all 0.4s ease-in-out;

        i {
            color: var(--buttonPrimaryColour) !important;
        }

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: var(--buttonPrimaryColour) !important;
            }
        }
    }

    &.disable-tune {
        background-color: #d3d3d3 !important; // Light grey color for disabled state
        border-color: #d3d3d3 !important;
        color: #a9a9a9 !important; // Dark grey color for disabled text
        cursor: not-allowed !important;

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: #a9a9a9 !important; // Dark grey color for disabled icons
            }
        }

        &:hover {
            background-color: #d3d3d3 !important;
            color: #a9a9a9 !important;
            border: 1px solid #d3d3d3 !important;
            cursor: not-allowed !important;

            i {
                color: #a9a9a9 !important;
            }

            span.svg-icon.svg-icon-block {

                path,
                rect {
                    fill: #a9a9a9 !important;
                }
            }
        }
    }
}

button.btn.btn-secondary.btn-style {
    border-color: var(--buttonSecondaryColour) !important;
    background-color: var(--buttonSecondaryColour) !important;
    color: var(--buttonTextColour) !important;
    border: 1px solid var(--buttonSecondaryColour) !important;

    span.svg-icon.svg-icon-block {

        path,
        rect {
            fill: var(--buttonTextColour) !important;
        }
    }

    &:hover {
        background-color: transparent !important;
        color: var(--buttonSecondaryColour) !important;
        border: 1px solid var(--buttonSecondaryColour) !important;
        transition: all 0.4s ease-in-out;

        i {
            color: var(--buttonSecondaryColour) !important;
        }

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: var(--buttonSecondaryColour) !important;
            }
        }
    }
}

button.btn.btn-danger.btn-style {
    border-color: var(--buttonDangerColour) !important;
    background-color: var(--buttonDangerColour) !important;
    color: var(--buttonTextColour) !important;
    border: 1px solid var(--buttonDangerColour) !important;

    span.svg-icon.svg-icon-block {

        path,
        rect {
            fill: var(--buttonTextColour) !important;
        }
    }

    &:hover {
        background-color: transparent !important;
        color: var(--buttonDangerColour) !important;
        border: 1px solid var(--buttonDangerColour) !important;
        transition: all 0.4s ease-in-out;

        i {
            color: var(--buttonDangerColour) !important;
        }

        span.svg-icon.svg-icon-block {

            path,
            rect {
                fill: var(--buttonDangerColour) !important;
            }
        }
    }
}

// body .btn.btn-primary {
//     border-color: var(--buttonPrimaryColour);
//     background-color: var(--buttonPrimaryColour);
//     color: var(--buttonTextColour) !important;
//     border: 1px solid var(--buttonPrimaryColour) !important;
// }

// .btn.btn-primary:focus:not(.btn-active) {
//     background-color: var(--buttonPrimaryColour) !important;
// }

// body .btn.btn-primary:hover {
//     color: var(--buttonTextColour) !important;
//     background-color: var(--buttonPrimaryColour) !important;
// }

// .btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g) {
//     fill: var(--buttonTextColour) !important;
// }

/* body .btn.btn-secondary { border-color: var(--buttonTextColour); background-color: var(--buttonTextColour); color: var(--white) !important;} */

/*body .btn.btn-danger { border-color: var(--dangerColour);  background-color: var(--dangerColour); color: var(--white) !important;}

body .btn.btn-bg-light {  border-color: var(--buttonTextColour); background-color: var(--buttonTextColour) !important; }

body .btn.btn-info { border-color: var(--infoColour);  background-color: var(--infoColour) !important; }*/

body .btn-check:checked+.btn.btn-info,
body .btn-check:active+.btn.btn-info,
body .btn.btn-info:focus:not(.btn-active),
body .btn.btn-info:hover:not(.btn-active),
body .btn.btn-info:active:not(.btn-active),
body .btn.btn-info.active,
body .btn.btn-info.show,
body .show>.btn.btn-info {
    border-color: var(--infoColour);
    background-color: var(--infoColour) !important;
}

body .btn-check:checked+.btn.btn-secondary,
body .btn-check:active+.btn.btn-secondary,
body .btn.btn-secondary:focus:not(.btn-active),
body .btn.btn-secondary:hover:not(.btn-active),
body .btn.btn-secondary:active:not(.btn-active),
body .btn.btn-secondary.active,
body .btn.btn-secondary.show,
body .show>.btn.btn-secondary {
    border-color: var(--buttonSecondaryColour);
    background-color: var(--buttonSecondaryColour);
}

body .btn-check:checked+.btn.btn-danger,
body .btn-check:active+.btn.btn-danger,
body .btn.btn-danger:focus:not(.btn-active),
body .btn.btn-danger:hover:not(.btn-active),
body .btn.btn-danger:active:not(.btn-active),
body .btn.btn-danger.active,
body .btn.btn-danger.show,
body .show>.btn.btn-danger {
    border-color: var(--buttonDangerColour);
    background-color: var(--buttonDangerColour);
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
    border-bottom: 1px solid var(--buttonPrimaryColour);
}

.aside-dark .menu .menu-item.hover>.menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: var(--navLinkActiveColour) !important;
}

.aside-dark .menu .menu-item.here>.menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item.show>.menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: var(--navLinkActiveColour) !important;
}

.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled),
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) .svg-icon svg {
    color: var(--buttonPrimaryColour);
}

.bg-primary {
    background-color: var(--buttonPrimaryColour) !important;
}

.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
    fill: var(--navLinkActiveColour) !important;
}